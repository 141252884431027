export default {
  namespaced: true,
  state: {
    userData: JSON.parse(localStorage.getItem('userData') || '{}'),
  },
  getters: {
    currentUser: state => {
      const { userData } = state
      return userData
    },
  },
  mutations: {
    UPDATE_USER(state, payload) {
      localStorage.setItem('userData', JSON.stringify(payload.user))
      state.userData = payload.user
    },
    LOGOUT_USER(state) {
      localStorage.setItem('userData', JSON.stringify({}))
      state.userData = {}
    },
  },
  actions: {},
}
