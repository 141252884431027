/* eslint-disable no-console */
import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/client/orders/Orders.vue'),
      meta: {
        pageTitle: 'Orders',
        breadcrumb: [
          {
            text: 'Orders',
            active: false,
          },
        ],
        resource: 'ACL',
        action: 'read',
      },
    },
    {
      path: '/history',
      name: 'history',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/client/history/History.vue'
        ),
      meta: {
        pageTitle: 'History',
        breadcrumb: [
          {
            text: 'History',
            active: false,
          },
        ],
        resource: 'ACL',
        action: 'read',
      },
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/client/analytics/Analytics.vue'
        ),
      meta: {
        pageTitle: 'Analytics',
        breadcrumb: [
          {
            text: 'Analytics',
            active: true,
          },
        ],
        resource: 'ACL',
        action: 'read',
      },
    },
    {
      path: '/menu',
      name: 'menu',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/client/Menu.vue'
        ),
      meta: {
        pageTitle: 'Menu',
        breadcrumb: [
          {
            text: 'Menu',
            active: true,
          },
        ],
        resource: 'ACL',
        action: 'read',
      },
    },

    {
      path: '/staff/users',
      name: 'staff-users',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/client/user/users-list/UsersList.vue'
        ),
      meta: {
        pageTitle: 'Staff Users',
        resource: 'staff',
        action: 'read',
      },
    },
    {
      path: '/staff-user-view',
      name: 'staff-user-view',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/client/user/user-view/UsersView.vue'
        ),
      meta: {
        pageTitle: 'Users',
        resource: 'staff',
        action: 'read',
      },
    },
    {
      path: '/staff/users/edit',
      name: 'staff-users-edit',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/client/user/user-edit/UsersEdit.vue'
        ),
      meta: {
        pageTitle: 'Users',
        resource: 'staff',
        action: 'write',
      },
    },
    {
      path: '/pos/analytics',
      name: 'pos-analytics',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/client/pos-analytics/Analytics.vue'
        ),
      meta: {
        pageTitle: 'POS Analytics',
        breadcrumb: [
          {
            text: 'POS Analytics',
            active: true,
          },
        ],
        resource: 'ACL',
        action: 'read',
      },
    },

    // Admin Routes
    {
      path: '/admin/users',
      name: 'users',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/admin/user/users-list/UsersList.vue'
        ),
      meta: {
        pageTitle: 'Users',
        resource: 'admin',
        action: 'write',
      },
    },
    {
      path: '/admin-user-view',
      name: 'admin-user-view',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/admin/user/user-view/UsersView.vue'
        ),
      meta: {
        pageTitle: 'Users',
        resource: 'admin',
        action: 'write',
      },
    },
    {
      path: '/admin-user-edit',
      name: 'admin-user-edit',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/admin/user/user-edit/UsersEdit.vue'
        ),
      meta: {
        pageTitle: 'Users',
        resource: 'admin',
        action: 'write',
      },
    },
    {
      path: '/admin/analytics',
      name: 'admin-analytics',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/admin/Analytics.vue'
        ),
      meta: {
        pageTitle: 'Analytics',
        resource: 'admin',
        action: 'write',
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/error/Error404.vue'
        ),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "sa-lazy" */ '@/views/error/NotAuthorized.vue'
        ),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

export default router
