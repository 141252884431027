import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3232/'
      : process.env.VUE_APP_API,
  timeout: 120000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // eslint-disable-next-line no-console
      console.error('You are not authorized')
      localStorage.setItem('accessToken', '')
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$http = axiosIns

export default axiosIns
