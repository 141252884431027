export default {
  namespaced: true,
  state: {
    deliveryOrDineIn: localStorage.getItem('deliveryOrDineIn') || 'delivery',
  },
  getters: {
    deliveryOrDineIn: state => state.deliveryOrDineIn,
  },
  setters: {
    deliveryOrDineIn: state => state.deliveryOrDineIn,
  },
  mutations: {
    SET(state, payload) {
      localStorage.setItem('deliveryOrDineIn', payload.deliveryOrDineIn)
      state.deliveryOrDineIn = payload.deliveryOrDineIn
    },
  },
  actions: {},
}
